<template>
    <div class="privacy">
        <span class="privacy-top">颜如玉-隐私政策</span><br/>
        <br/>
        我们于前述时间更新了《颜如玉用户隐私政策》的相关内容。本次更新的内容主要包括：依照最新法律要求更加详细地介绍了我们对用户个人信息的保护规则、增加了与我们合作的第三方平台信息、重点标注属于个人敏感信息的内容等。请您在使用继续使用颜如玉产品或服务前仔细阅读和充分理解全文，并在同意全部内容后使用或继续使用。<br/>
        <br/>
        导言<br/>
        <br/>
        颜如玉（以下也称“我们”，运营者：广州颜如玉网络有限公司）深知个人信息对您的重要性，因此我们非常重视保护您的隐私和个人信息，我们亦将本《颜如玉用户隐私政策》（以下也称“本政策”）中的内容以高度审慎的义务对待和处理。本政策与您所使用的我们的产品或服务息息相关，您在下载、安装、启动、浏览、注册、登录、使用我们的产品或服务时，我们将按照本政策的约定处理和保护您的个人信息，因此我们希望您能够仔细阅读、充分理解本政策的全文，并在需要时，按照本政策的指引，作出您认为适当的选择。本政策之中涉及的相关技术词汇，我们尽量以简明扼要的表述向您解释，以便于您理解。 本政策适用于我们基于颜如玉平台及第三方平台提供的所有产品和、服务及软件。某些特定产品、服务及软件还将适用特定的个人信息保护政策，我们将在向您提供这些特定产品和/或服务及软件前进一步说明。在特定情况下，我们可能通过弹窗等方式获取您的授权同意，弹窗方案将构成本政策的有效组成部分。如特定的个人信息保护政策与本政策有不一致之处，以特定的个人信息保护政策为准。特定的个人信息保护政策未提及之处，以本政策为准。 需要特别说明的是，本政策不适用于由颜如玉关联方或第三方合作机构直接向您提供的产品或服务。<br/>
        <br/>
        本政策以《中华人民共和国网络安全法》等关于个人信息保护的法律法规以及相关国家、行业标准为依据，透明地呈现我们如何收集、使用、存储、共享、转让、公开披露您的个人信息，以及您享有的对个人信息的控制权。 您知晓并确认您在注册时已阅读本政策的所有内容，同意我们按照本政策来合法处理您的个人信息。为便于您阅读本政策，我们对所有可能与您的合法利益存在重大关系的条款以及涉及敏感信息的内容都通过粗体特别标识，请您留意阅读。在向我们提供您的任何个人敏感信息前，请您考虑清楚该等提供是恰当的并且同意您的个人敏感信息可按本政策所述的目的和方式进行处理。我们会在得到您的同意后收集和使用您的敏感信息以实现相关功能。您可以拒绝向我们提供这些敏感信息，但是拒绝提供这些信息会影响您使用颜如玉产品和/或服务及软件项下的特定功能。<br/>
        本《颜如玉用户隐私政策》将帮助您了解以下内容<br/>
        一、 《颜如玉用户隐私政策》适用范围、相关词语涵义<br/>
        二、 我们收集的用户信息及收集方式<br/>
        三、我们如何储存搜集的用户信息<br/>
        四、 我们如何使用Cookie和同类技术<br/>
        五、 我们如何共享收集的用户信息<br/>
        六、我们如何保证收集的用户信息安全<br/>
        七、用户如何管理自己的信息<br/>
        八、未成年人保护<br/>
        九、本个人信息保护政策修订及更新<br/>
        十、其他相关信息<br/>
        <br/>
        <br/>
        一、本《颜如玉用户隐私政策》适用范围、相关词语涵义<br/>
        （一）本《颜如玉用户隐私政策》适用范围<br/>
        本《颜如玉用户隐私政策》为我们的所有产品或服务统一适用的通用内容，当您使用我们的任何产品或服务时，本《颜如玉用户隐私政策》即适用，但如该产品或服务单独设置了隐私条款/隐私协议的，单独的隐私条款/隐私协议优先适用；单独的隐私条款/隐私协议未提及的内容，适用本政策。但请您注意，本《颜如玉用户隐私政策》不适用于以下情况：<br/>
        为我们的产品与/或服务提供数据服务的第三方的信息收集；<br/>
        我们的产品或服务可能会包含或链接至第三方提供的信息或第三方服务（包括任何第三方应用、网站、产品、服务等），这些信息或服务由第三方负责运营，您使用该等信息或服务与我们无关。我们在此善意的提醒您，您在使用第三方信息或服务时，应留意和仔细阅读第三方向您展示的相关用户协议和个人信息保护政策，并妥善保管和谨慎提供您的个人信息。本《颜如玉用户隐私政策》仅适用于我们所收集的您的个人信息，并不适用于任何第三方对您的个人信息的收集，以及任何第三方提供的服务或第三方的信息使用规则，我们对任何第三方收集、储存和使用的您个人信息的行为在法律允许的范围内亦不承担任何责任。<br/>
        （二）相关词语涵义<br/>
        我们的产品与/或服务：包括但不限于我们提供的软件、网站、服务（含站外服务，例如：我们的数据服务和“通过我们的服务申请或分享”的插件等）以及包含的相关产品或服务功能。<br/>
        我们需要特别提醒您的是：本《颜如玉用户隐私政策》为我们统一适用的一般性隐私条款，针对我们的某些特定服务，我们还将制定特定隐私条款或隐私协议，以便更具体地向您阐明我们的隐私规则和保护措施等内容。<br/>
        <br/>
        以下专业术语的定义均出自于GB/T 35273-2020《信息安全技术 个人信息安全规范》：<br/>
        <br/>
        个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。本个人信息保护政策中涉及的个人信息包括个人基本资料（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱）、个人身份信息（包括身份证信息）、个人生物识别信息（包括指纹、面部特征）、网络身份标识信息（包括系统账号、IP地址、邮箱地址以及与前述有关的密码、口令、口令保护答案、用户个人数字证书）、个人财产信息（包括银行账号、鉴别信息（口令）、资金信息（包括资金数量、收益、余额）、交易和消费记录、流水记录、虚拟货币、通讯录信息、个人上网记录（包括网站浏览记录、软件使用记录、点击记录）、个人常用设备信息（包括硬件序列号、硬件型号、设备MAC地址、操作系统类型、软件列表、唯一设备识别码（如IMEI/android ID/IDFA/OPENUDID/GUID、SIM卡IMSI信息等在内的描述个人常用设备基本情况的信息）、个人位置信息（包括大概地理位置、精准定位信息）。我们实际具体收集的个人信息种类以下文描述为准。<br/>
        个人敏感信息：指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本个人信息保护政策中涉及的个人敏感信息包括您的财产信息（包括银行账号、鉴别信息（口令）、资金信息（包括资金数量、收益、余额）、交易和消费记录、流水记录、虚拟货币、个人生物识别信息（包括指纹、面部特征）、个人身份信息（包括身份证信息）、网络身份识别信息（包括系统账号、邮箱地址以及与前述有关的密码、口令、口令保护答案、用户个人数字证书）、其他信息（包括通讯录信息、个人电话号码、精准定位信息、收货地址、网页浏览记录）、儿童个人信息。相比其他个人信息，个人敏感信息可能对您的个人权益影响更大。我们在此善意的提醒您需在谨慎考虑后再向我们提供您的个人敏感信息。我们实际具体收集的个人敏感信息种类以下文描述为准。<br/>
        个人信息主体：个人信息所标识或者关联的自然人。<br/>
        个人信息控制者：有能力决定个人信息处理目的、方式等的组织或个人。<br/>
        明示同意：个人信息主体通过书面、口头等方式主动做出纸质或电子形式的声明，或者自主作出肯定性动作，对其个人信息进行特定处理做出明确授权的行为。<br/>
        授权同意：个人信息主体对其个人信息进行特定处理做出明确授权的行为。包括通过积极的行为做出授权，或者通过消极的不作为而做出授权。<br/>
        儿童：指不满十四周岁的未成年人。<br/>
        匿名化：指通过对个人信息的技术处理，使得个人信息主体无法被识别，且处理后的信息不能被复原的过程。<br/>
        去标识化：指通过对个人信息的技术处理，使其在不借助额外信息的情况下，无法识别个人信息主体的过程。<br/>
        删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。<br/>
        <br/>
        二、我们收集的用户信息及收集方式<br/>
        在您使用颜如玉产品和/或服务及软件时，我们需要/可能需要收集和使用您的个人信息包括如下两种：<br/>
        1、为实现向您提供颜如玉产品和/或服务及软件基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用颜如玉产品和/或服务及软件；<br/>
        2、为实现向您提供颜如玉产品和/或服务及软件的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用颜如玉产品和/或服务及软件的基本功能。<br/>
        我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用颜如玉产品和/或服务及软件过程中主动提供的，因使用颜如玉产品和/或服务及软件而产生的，以及我们从第三方处获取的您的个人信息。提示您注意：如果您提供的是他人的个人信息，请您确保已取得相关主体的授权。<br/>
        由于我们的产品和服务较多，为用户提供的内容也不同，因此也会因产品/服务的内容不同而对用户信息的收集有所区别，具体以产品或服务实际提供为准。除此之外，您理解并同意，我们希望提供给您的产品和服务是完善的，所以我们会不断改进我们的产品和服务，包括技术，这意味着我们可能会经常推出新的业务功能，可能需要收集新的个人信息或变更个人信息使用目的或方式。如果某一需要收集用户的个人信息的功能或产品和服务未能在本《颜如玉用户隐私政策》中予以说明的，我们会通过更新本政策并以网站公告等方式另行说明该信息收集的目的、内容、使用方式和范围，并为用户提供自主选择同意的方式，且在征得用户公开同意后收集。在此过程中，如果用户有任何疑惑或相关事宜的，可以通过文末提供的方式联系我们，我们会做出相应解答。<br/>
        3、我们的具体场景包括：<br/>
        ● 注册账号<br/>
        当用户注册账号时，我们需要收集您的一些信息，包括如下个人信息：昵称、手机号、生日、微信号、简介、职业、身高、体重、常驻城市；其中微信号和简介为可选填写内容，不填写也可以完成注册；同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们最核心的账号注册功能，如您拒绝提供上述权限将可能导致您无法使用我们的产品与服务。<br/>
        ● 查看附近的人<br/>
        当用户查看附近的人时，我们需要收集您的一些信息，包括如下个人信息：当前地理位置；同时为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限，我们收集这些信息是为了向您提供我们最核心的查看附近的人功能，如您拒绝提供上述权限将可能导致您无法使用我们的产品与服务。<br/>
        ● 录制语音功能<br/>
        当用户使用录制语音等时，我们将访问用户的设备录音相关权限，我们需要收集用户的语音信息、语音交互信息（个人信息）。如用户拒绝提供的仅会使用户无法使用该功能，但并不影响用户正常使用产品与/或服务的其他功能。同时，用户也可以随时通过相关功能设置开启/取消该权限（例如：手机系统设置—隐私—麦克风—颜如玉）。用户开启该权限即视为用户授权我们可以访问、获取、收集、使用用户的该等个人信息；但当用户取消该授权后，我们将不再收集该信息，也无法再为用户提供上述与之对应的服务；但除非用户依照法律的规定删除了用户的个人信息，否则用户的取消行为不会影响我们基于用户之前的授权进行的个人信息的处理、存储。<br/>
        ● 上传图片和视频功能<br/>
        如果用户选择上传视频或照片，我们将为便于用户上传图片而经过用户的同意后访问用户的手机相册权限，用户也可以随时通过相关功能设置开启/取消该权限（例如：手机系统设置—隐私—相机—颜如玉）。用户开启该权限即视为用户授权我们可以访问、获取、收集、使用用户的该等个人信息；当用户取消该授权后，我们将不再收集该信息，也无法再为用户提供上述与之对应的服务；但除非用户依照法律的规定删除了用户的个人信息，否则用户的取消行为不会影响我们基于用户之前的授权进行的个人信息的处理、存储。例如用户在颜如玉中相册上传的照片。如无需在我方平台上传或发布，可无需提供上述信息。不提供上述信息不妨碍用户使用“颜如玉”的核心功能。<br/>
        ● 支付功能<br/>
        当用户在“颜如玉”平台购买会员和其它充值服务时，需要提供一些下单与完成交易所需要的信息，例如：交易订单号、支付账户名称、支付渠道及支付金额等，此外，我们也可能会再收集一些其他与订单相关的信息，我们收集这些信息是为了顺利完成交易、保障用户的交易安全、查询订单信息、提供客服和售后服务等。<br/>
        ● 身份认证功能<br/>
        当用户使用人脸认证相关功能或需要对身份认证的服务时，为满足相关法律规定及监管要求、确保用户身份真实性、实现反欺诈等风险控制、保障系统和服务安全或提供服务之需要我们需要收集用户面部特征信息（静态或动态）、唯一设备标识符（IMEI码）、网络设备硬件地址（MAC）、通信运营商及归属地、手机号、国际移动设备识别码(IMEI)（个人敏感信息）等。我们收集这些信息是为了用户的真实身份认证等，面部特征信息、IMEI属于个人敏感信息，我们仅会在特定场景下和本政策范围内获取和使用，我们会高度对待和谨慎处理您的提供的上述信息（包括高强度加密保护等）。<br/>
        ● 客服、其他用户响应功能<br/>
        当用户联系我们的客服或使用其他用户响应功能时（包括：提出我们的产品与/或服务的售后申请、行使您的相关个人信息控制权、其他客户投诉和需求），为了用户的账号与系统安全，我们可能需要用户先行提供账号信息，并与用户之前的个人信息相匹配以验证您的用户身份。在用户使用客服或其他用户响应功能时，我们可能还会需要收集您的如下个人敏感信息：联系方式（用户与我们联系时使用的电话号码/电子邮箱或用户向我们主动提供的其他联系方式）、用户与我们的沟通信息（包括文字/图片/音视频/通话记录形式）、与用户需求相关联的其他必要信息。我们收集这些信息是为了调查事实与帮助用户解决问题，如用户拒绝提供可能导致用户无法使用我们的客服等用户响应机制。<br/>
        ● 其他<br/>
        如上文所述，如果某一需要收集您的个人信息的功能或产品/服务未能在本《颜如玉用户隐私政策》中予以说明的，或者我们超出了与收集您的个人信息时所声称的目的及具有直接或合理关联范围的，我们将在收集和使用你的个人信息前，通过更新本《颜如玉用户隐私政策》、页面提示、弹窗、消息通知、网站公告或其他便于您获知的方式另行向您说明，并为您提供自主选择同意的方式，且在征得您明示同意后收集和使用。<br/>
        用户充分理解并同意，我们在以下情况下收集、使用的个人信息无需用户的授权同意，且我们可能不会响应用户提出的更正/修改、删除、注销、索取信息的请求，可能包括以下方面：<br/>
        ▪ 与国家安全、国防安全有关的；<br/>
        ▪ 与公共安全、公共卫生、重大公共利益有关的；<br/>
        ▪ 与犯罪侦查、起诉、审判和执行判决等有关的；<br/>
        ▪ 与犯罪侦查、起诉、审判和判决执行等直接相关的；或根据法律法规的要求、行政机关或公检法等机关的要求的；<br/>
        ▪ 出于保证用户或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
        ▪ 所收集的信息是用户自行向社会公开的或者是从合法公开的渠道中收集到的；<br/>
        ▪ 根据与用户签订和履行相关协议或其他书面文件所必需的；<br/>
        ▪ 我们有充分证据表明用户存在主观恶意或滥用权利的；<br/>
        ▪ 对收集的个人信息已经进行脱敏处理的；<br/>
        ▪ 为合法的新闻报道所必需的；<br/>
        ▪ 作学术研究用途，例如为科研机构开展统计或学术研究之必要；<br/>
        ▪ 基于社会公共利益等；<br/>
        ▪ 用于维护我们的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；<br/>
        ▪有权机关的要求、法律法规等规定的其他情形。<br/>
        <br/>
        三、我们如何存储收集的用户信息<br/>
        1、我们会通过安全的方式存储用户的信息，包括本地存储（例如利用APP进行数据缓存）、数据库和服务器日志。<br/>
        2、一般情况下，我们只会在为实现服务目的所必需的时间内或法律法规规定的条件下存储用户的个人信息。<br/>
        3、 存储地点：我们依照法律法规的规定，将您的个人信息存储于中华人民共和国境内。目前我们暂时不存在跨境存储您的个人信息或向境外提供个人信息的场景。将来如需跨境传输或存储的，我们会向相关方告知信息出境的目的、接收方、安全保证措施和安全风险，并征得用户及监管部门的同意。<br/>
        4、当我们的产品或服务发生停止运营的情况时，我们将以推送通知、公告等形式通知用户，并在合理期限内删除用户的个人信息或进行匿名化处理，法律法规另有规定的除外。<br/>
        <br/>
        四、我们如何使Cookie和同类技术<br/>
        1、在用户未拒绝接受cookies的情况下，“颜如玉”会在用户的计算机上设定或取用cookies，数据信息都采用匿名的方式，以便用户能登录或使用依赖于cookies的“颜如玉”平台服务或功能，为用户提供更加周到的个性化服务和推广服务。<br/>
        2、如果用户的浏览器允许，用户可以通过浏览器的设置以管理、（部分/全部）拒绝Cookie与/或同类技术；或删除已经储存在用户的计算机、移动设备或其他装置内的Cookie与/或同类技术，从而实现我们无法全部或部分追踪用户的个人信息。用户如需详细了解如何更改浏览器设置，请具体查看用户使用的浏览器的相关设置页面。您理解并知悉：我们的某些产品/服务只能通过使用Cookie或同类技术才可得到实现，如用户拒绝使用或删除的，用户可能将无法正常使用我们的相关产品与/或服务或无法通过我们的产品与/或服务获得最佳的服务体验，同时也可能会对用户的信息保护和账号安全性造成一定的影响。<br/>
        <br/>
        五、我们如何共享、转让、披露收集的用户信息<br/>
        1、共享<br/>
        我们会重视对您的个人信息的保护，您的个人信息是我们为您提供产品或服务的重要依据和组成部分，对于您的个人信息，我们仅在本《颜如玉用户隐私政策》所述目的和范围内或根据法律法规的要求收集和使用，并严格保密，我们不会与颜如玉以外的第三方公司、组织和个人共享您的个人信息，除非存在以下一种或多种情形：<br/>
        ▪ 您自行提出要求的；<br/>
        ▪ 事先已征得您的明确授权同意；<br/>
        ▪ 与第三方业务合作伙伴的必要共享；<br/>
        您理解并同意，为了必要/合理的业务的顺利开展、满足您的要求、履行我们在相关用户协议或本个人信息保护政策中的义务和行使我们的权利或遵守法律规定等目的，我们可能需要向部分业务合作伙伴（第三方服务供应商、承包商、代理、数据分析合作伙伴、应用开发者等，例如，为我们提供位置服务的地图服务供应商）共享您的个人信息，我们承诺要求第三方按照我们的说明、个人信息保护政策以及其他相关的保密和安全措施来为我们处理上述信息，并用于以下用途：<br/>
        <br/>
        我们的业务合作伙伴包括：<br/>
        ●为我们的产品与/或服务提供功能支持的服务提供商：包括：提供支付服务的支付机构、提供一键登录服务的运营商和其他服务提供商，我们共享信息的目的是可以实现我们产品与/或服务的功能，让您可以正常使用；<br/>
        ●提供技术、咨询服务的供应商。我们可能会将您的个人信息共享给支持我们提供服务的第三方。这些机构包括为我们提供基础设施技术服务、数据处理和客户服务的机构。但我们要求这些服务提供商只能出于为我们提供服务的目的使用您的信息，而不得出于其自身利益使用您的信息。<br/>
        ●分析信息类的合作伙伴：为维护/改进我们的产品/服务、为您提供更好的内容，我们可能会与提供该服务的指定合作伙伴共享您的相关信息（包括：终端型号、设备信息（如android-id）、您所在的城市及其他相关信息）。对于分析数据的伙伴，为了更好的分析颜如玉用户的使用情况，我们可能向其提供颜如玉用户的数量、地区分布、活跃情况等数据，但我们仅会向这些合作伙伴提供不能识别个人身份的统计信息。我们承诺：未经您的同意，我们不会与其共享可以识别您身份的个人信息；<br/>
        ●为我们提供安装包加固服务的合作伙伴，例如为360加固我们的的应用程序避免出现安全漏洞，我们会将您的个人信息共享给360，包括应用IMEI信息、个人常用设备；为实现“应用盗版检测”功能，360将使用您已安装的应用列表及活用情况；为实现“崩溃日志分析”，在您使用我们产品发生崩溃时，360将使用您的手机系统内核版本、当前系统时间、异常类型及内容等。<br/>
        ●第三方SDK类服务商：我们产品中可能会包含第三方SDK或其他类似的应用程序，如您在我们平台上使用这类由第三方提供的服务时，您同意将由其直接收集和处理您的信息，我们将第三方会收集到的敏感信息进行了字体加重标注，请在授权第三方使用此类信息时谨慎考虑。以下为第三方SDK类服务商名单<br/>
        <br/>
        <table border="1" style="border-collapse: collapse;">
            <tr>
                <th>SDK产品名称</th>
                <th>场景描述</th>
                <th>个人信息类型</th>
                <th>个人信息字段</th>
                <th>是否为必要信息</th>
                <th>隐私政策链接</th>
            </tr>
            <tr>
                <td>极光SDK</td>
                <td>推送、登录</td>
                <td>个人常用设备</td>
                <td>APP安装信息、IMEI信息</td>
                <td>是</td>
                <td><a href="https://www.jiguang.cn/license/privacy">https://www.jiguang.cn/license/privacy</a></td>
            </tr>
            <tr>
                <td>支付宝SDK</td>
                <td>支付</td>
                <td>个人常用设备</td>
                <td>IMEI信息和MAC地址</td>
                <td>是</td>
                <td><a href="https://render.alipay.com/p/c/k2cx0tg8">https://render.alipay.com/p/c/k2cx0tg8</a></td>
            </tr>
            <tr>
                <td>微信SDK</td>
                <td>支付</td>
                <td>个人常用设备</td>
                <td>IMEI信息</td>
                <td>是</td>
                <td><a href="https://www.wechat.com/zh_CN/privacy_policy.html">https://www.wechat.com/zh_CN/privacy_policy.html</a></td>
            </tr>
            <tr>
                <td>友盟SDK</td>
                <td>追踪归因、数据统计</td>
                <td>个人常用设备</td>
                <td>APP安装信息、IMEI信息</td>
                <td>是</td>
                <td><a href="https://www.umeng.com/page/policy?spm=a213m0.22488127.0.0.3cb275efPPmdLs">https://www.umeng.com/page/policy?spm=a213m0.22488127.0.0.3cb275efPPmdLs</a></td>
            </tr>
            <tr>
                <td>高德SDK</td>
                <td>通过GPS、基站定位、WiFi的混合定位模式，提供精准定位</td>
                <td>个人常用设备、位置信息</td>
                <td>手机品牌、手机型号、系统版本、App软件列表，以及加密后的国际移动设备码（IMEI）、安卓ID和iOS广告标识符（IDFA）、IP地址</td>
                <td>是</td>
                <td><a href="https://developer.amap.com/product/locate">https://developer.amap.com/product/locate</a></td>
            </tr>
            <tr>
                <td>腾讯TimSDK</td>
                <td>即时通讯</td>
                <td>个人常用设备</td>
                <td>账号信息</td>
                <td>是</td>
                <td><a href="https://cloud.tencent.com/document/product/548/50955">https://cloud.tencent.com/document/product/548/50955</a></td>
            </tr>
            <tr>
                <td>阿里OneSDK</td>
                <td>支付宝支付</td>
                <td>个人常用设备</td>
                <td>支付宝账户的信息</td>
                <td>是</td>
                <td><a href="https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662649.1998907877.3.6848be48IrSJk9">https://terms.alicdn.com/legal-agreement/terms/suit_bu1_taobao/suit_bu1_taobao202107141244_11649.html?spm=a3c0d.7662649.1998907877.3.6848be48IrSJk9</a></td>
            </tr>
            <tr>
                <td>腾讯RTMP</td>
                <td>音视频聊天</td>
                <td>个人常用设备</td>
                <td>（1）摄像头/麦克风捕获的音视频 （2）设备信息：将获取设备型号、系统版本、网络类型、屏幕分辨率、CPU 信息</td>
                <td>是</td>
                <td><a href="https://cloud.tencent.com/document/product/454/61839">https://cloud.tencent.com/document/product/454/61839</a></td>
            </tr>
            <tr>
                <td>阿里OSS</td>
                <td>云存储</td>
                <td>个人常用设备</td>
                <td>阿里云真人认证使用</td>
                <td>是</td>
                <td><a href="https://cloud.tencent.com/document/product/548/50955">https://cloud.tencent.com/document/product/548/50955</a></td>
            </tr>
            <tr>
                <td>vivo推送</td>
                <td>消息推送</td>
                <td>设备信息</td>
                <td>设备标识OAID和加密的Android ID，以及使用推送服务的应用信息如应用包名、版本号和运行状态</td>
                <td>是</td>
                <td><a href="https://dev.vivo.com.cn/documentCenter/doc/366#w1-08512613">https://dev.vivo.com.cn/documentCenter/doc/366#w1-08512613</a></td>
            </tr>
            <tr>
                <td>小米推送</td>
                <td>消息推送</td>
                <td>设备信息</td>
                <td>设备标识OAID和加密的Android ID，以及使用推送服务的应用信息如应用包名、版本号和运行状态</td>
                <td>是</td>
                <td><a href="https://dev.mi.com/console/doc/detail?pId=1822">https://dev.mi.com/console/doc/detail?pId=1822</a></td>
            </tr>
            <tr>
                <td>魅族推送</td>
                <td>消息推送</td>
                <td>设备信息</td>
                <td>主要包括设备名称、设备型号、地区和语言设置、设备识别码（IMEI 号码等）、设备硬件信息与状态、使用习惯、IP 地址、Mac 地址、操作系统版本，以及用于访问服务的设备的设置信息。</td>
                <td>是</td>
                <td><a href="https://www.meizu.com/legal.html">https://www.meizu.com/legal.html</a></td>
            </tr>
            <tr>
                <td>中国移动授权登录</td>
                <td>一键登录</td>
                <td>个人常用设备</td>
                <td>手机号码</td>
                <td>是</td>
                <td><a href="https://ecloud.10086.cn/op-help-center/doc/article/27419">https://ecloud.10086.cn/op-help-center/doc/article/27419</a></td>
            </tr>
        </table>
        <br/>
        前述服务商收集和处理信息等行为遵守其自身的隐私条款，而不适用于本政策。但我们也会努力审查该第三方的业务准入资质并努力要求该等服务商的合法合规性与安全性。为了最大程度保障您的信息安全，我们强烈建议您在使用任何第三方SDK类服务前仔细阅读其隐私条款。为保障您的合法权益，如您发现这等SDK或其他类似的应用程序存在风险时，建议您立即终止相关操作并及时与我们取得联系；<br/>
        上述共享的是去标识化的信息，且接受该信息共享的第三方无法重新识别信息主体身份；<br/>
        某些情况下，只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务，或处理您与他人的纠纷或争议：<br/>
        ● 依据您与我们签署的相关协议（例如：在线协议、平台规则等）或法律文件而共享的；<br/>
        ● 符合您与其他第三人之间的有关约定的；<br/>
        ● 基于合理商业习惯而共享的，例如：与第三方共享联合营销活动中的中奖/获胜者等信息，以便其能及时向您发放奖品/礼品等；我们接受尽调时等；<br/>
        ● 基于学术研究而使用，例如：以学术研究为目的而与学术机构共享我们使用您的相关信息集合形成的间接用户画像、去标识化或匿名化处理后的分析/统计类信息（未经您的同意，我们不会与其共享可以识别您身份的个人信息）；学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行匿名化处理的；<br/>
        ● 有权机关的要求、诉讼争议需要、法律法规等规定的其他需要共享的情形。<br/>
        <br/>
        您可以基于颜如玉平台与第三人（包括不特定对象）共享您的个人信息或其他信息，但因您的共享行为而导致的信息泄露、被使用等情况，与我们无关，您需要自行承担相应的法律责任。<br/>
        我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息。在我们与业务合作伙伴共享信息时，我们会以多种方式保护您的个人信息，包括但不限于：（1）如具备上述合理事由且需要您授权同意的，我们会在共享前向您告知共享的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信息的内容）并在取得您授权同意后再共享；（2）我们的安全团队将对信息数据的输出形式、流转、使用等做安全评估与处理等。请您知悉，即使已经取得您的授权同意，我们也仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并尽量对共享内容中的个人信息进行匿名化处理。您理解并知悉，匿名化处理后的信息无法指向与识别您，已不属于法律意义上的个人信息，其处理、使用、共享、转让无需征得您的授权同意。<br/>
        <br/>
        2、转让<br/>
        转让是指将个人信息控制权向其他公司、组织或个人转移的过程。原则上我们不会将您的个人信息转让，但以下情况除外：<br/>
        ● 您自行提出要求的；<br/>
        ● 事先已征得您的明确授权同意；<br/>
        如我们进行兼并、收购、重组、分立、破产、资产转让或类似的交易，而您的个人信息有可能作为此类交易的一部分而被转移，我们会要求新持有人继续遵守和履行该《颜如玉用户隐私政策》的全部内容（包括使用目的、使用规则、安全保护措施等），否则我们将要求其重新获取您的明示授权同意；<br/>
        法律法规等规定的其他情形。<br/>
        如具备上述事由确需转让的，我们会在转让前向您告知转让的信息的目的、类型（如涉及您的个人敏感信息的，我们还会向您告知涉及的敏感信息的内容），并在征得您的明示同意后再转让，但法律法规另有规定的或本政策另有约定的除外。<br/>
        3、公开披露<br/>
        我们不会将用户的个人信息转让给任何公司、组织和个人，但以下情况除外：<br/>
        ● 在获取明示同意的情况下转让：获得用户的明示同意后，我们会向其他方转让用户的个人信息；<br/>
        ● 在我方平台服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有用户个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向用户征求授权同意。<br/>
        ● 与国家安全、国防安全有关的；<br/>
        ● 与公共安全、公共卫生、重大公共利益有关的；<br/>
        ● 与犯罪侦查、起诉、审判和判决执行等有关的；<br/>
        ● 出于维护个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
        ● 用户自行向社会公众公开的个人信息<br/>
        ● 从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道<br/>
        <br/>
        六、我们如何保证收集的用户信息安全<br/>
        “颜如玉”一直都极为重视保护用户的个人信息安全，为此我们采用了符合行业标准的安全技术措施及配套的组织架构和管理体系等多层面保护措施以最大程度降低用户的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。包括：<br/>
        ●数据安全传输方面，采用传输层安全协议等密码技术，通过Https等方式防止传输链路被嗅探、窃听截取风险，建立安全的隐私数据采集环境，保证数据采集的私密性和完整性；<br/>
        ●数据安全存储方面，对数据进行分类分级处置并对用户个人敏感信息采取独立、加密存储等额外的安全保护措施；<br/>
        ●数据访问和使用的安全控制方面，实施严格的数据权限控制机制，采取多重身份认证技术，并对能够处理用户的信息的行为进行监控，避免数据被违规访问和未授权使用；<br/>
        ●建立完整的审计机制，对数据生命周期的全流程进行监控与审计，防止用户的个人信息遭遇未经授权的访问、公开披露、使用、修改、人为或意外的损坏或丢失；<br/>
        ●成立专门的个人信息保护责任部门，建立相关的内控管理流程，对可能接触到用户的信息的工作人员采取最小化权限原则；<br/>
        ●建立数据分类分级制度、业务数据安全使用规范、数据合作规范等管理体系，保障用户的信息在收集、传输、使用、存储、转移、销毁等环节的处置满足法律法规相关规范和安全要求；<br/>
        ●合作协议条款保证<br/>
        ▪ 在我们从第三方间接收集您的个人信息前，我们会明确以书面形式（如合作协议、承诺书）要求该第三方在已经取得您明示同意后收集以及处理个人信息，且我们使用的信息不会超出您授权同意的范围，我们还会在书面协议层面要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求该第三方承担相应法律责任；<br/>
        ▪ 在我们向业务合作伙伴共享您的个人信息前，我们会严格要求合作伙伴的信息保护义务与责任，并要求业务合作伙伴在合作前签署关于数据安全的保护协议，一旦业务合作伙伴有任何违反协议的行为，将须承担相应法律责任；<br/>
        ▪ 其他合作协议中明确约定的内容。<br/>
        ●其他可行的安全组织和管理措施。<br/>
        ▪ 在我们从第三方间接收集用户的个人信息前，会明确以书面形式（如合作协议、承诺书）要求该第三方在已经取得用户明示同意后收集以及处理（如共享等）个人信息，在书面协议层面要求第三方对个人信息来源的合法性和合规性做出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；<br/>
        ▪ 在我们向业务合作伙伴共享用户个人信息前，我们会严格要求合作伙伴的信息保护义务与责任，为此，我们的法律部门还专门修订了同业务合作伙伴间的《信息安全保护协议》，并要求业务合作伙伴在合作前签署，协议严格约定了业务合作伙伴的用户信息保密义务，包括信息的保管、使用和流转等均应满足我们的管控要求，接受我们的审核、监督和审计等，一旦有任何违反，将承担相应法律责任；<br/>
        ▪ 我们建立了合理有效、科学健全的安全应急响应体系，明确了网络安全事件的分类分级标准、安全应急响应组织及职责，并根据安全事件的不同类型和级别制定了适当的应急响应预案，明确规范了安全事件的报告流程和应急响应处置流程。同时，我们还具备“颜如玉”安全应急响应中心，由安全技术和运营团队负责维护，便于及时有效的响应、处置各类安全漏洞和突发事件，联合相关部门进行安全事件溯源和打击；<br/>
        ▪ 如不幸发生个人信息安全事件的，我们将按照法律法规的要求，及时向用户告知安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、用户可自主防范和降低风险的建议、对用户的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知用户，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。<br/>
        ▪ 请用户知悉并理解，互联网并非绝对安全的环境，我们强烈建议通过安全方式、使用复杂密码，协助我们保证用户的账号安全。如发现自己的个人信息泄密，尤其是用户自己的账户或密码发生泄露，请立即根据本个人信息保护政策文末中提供的联系方式联络我们，以便我们采取相应措施来保护用户个人信息安全。<br/>
        <br/>
        七、用户如何管理自己的信息<br/>
        1、“颜如玉”按《中华人民共和国网络安全法》的要求，通过提供便捷的方法，让您可以访问、更正及删除自己的账户信息，以及使用我们的服务时用户提供的其他个人信息。您可以通过如下方式访问及管理用户的个人信息。<br/>
        2、修改个人信息——您可以通过“我的—个人资料”对您填写的个人信息进行修改。在以下情形中，用户可以向我们提出删除个人信息的请求：<br/>
        ●如果我们处理个人信息的行为违反法律法规；<br/>
        ●如果我们收集、使用用户的个人信息，却未征得明确同意；<br/>
        ●如果我们处理个人信息的行为严重违反了约定；<br/>
        ●如果用户不再使用我们的产品或服务，或用户主动注销了账号；<br/>
        ●如果我们永久不再为用户提供产品或服务。<br/>
        3、个人信息主体注销账户——您如果需要注销颜如玉的账号，可以通过“设置-注销账户”实现。也可联系我方平台客服，客服在核实用户身份后，会释放用户的手机号和邮箱，达到注销的效果，账户注销不是退出登录，在您点击注销账户按钮后会出现《账户注销协议》界面，请您在仔细阅读《账户注销协议》并确认您了解账户注销的影响后再确认注销。<br/>
        4、在用户主动注销账户之后，我们将停止提供产品或服务，根据适用法律的要求删除用户个人信息，或使其匿名化处理。用户在注销账号前如果有在我方平台上传的视频，如果不希望相关视频在我方平台继续传播，请自行删除。如未选择删除的，将视为同意该等视频继续授权我方平台进行信息网络传播。如用户选择不删除相关上传的视频，在注销账号后，该等视频在我方平台上展示时上传人信息将做匿名脱敏处理。<br/>
        八、未成年人保护条款<br/>
        1、我们的产品是恋爱交友类型APP，禁止未成年人使用。<br/>
        九、本个人信息保护政策修订及更新<br/>
        1、“颜如玉”有权修改个人信息保护政策并在修订生效前通过我方平台公告或以其他适当方式通知用户，该情况下，请您仔细阅读新版的隐私协议并对我们进行明示授权。若您点击了“同意”等按钮并继续使用我们的服务，即表示同意受经修订的我方平台隐私权政策的约束。<br/>
        2、未经用户明确同意，我们不会限制用户按照本隐私权政策所应享有的权利。我们会在“颜如玉”专门链接上发布对隐私权政策所做的任何变更和更新。<br/>
        3、对于重大变更，我们还会提供更为显著通知。本政策所指的重大变更包括但不限于：<br/>
        ● 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；<br/>
        ● 我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；<br/>
        ● 个人信息共享、转让或公开披露的主要对象发生变化；<br/>
        ● 用户参与个人信息处理方面的权利及其行使方式发生重大变化；<br/>
        ● 我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；<br/>
        ● 个人信息安全影响评估报告表明存在高风险时。<br/>
        <br/>
        十、其他相关信息<br/>
        1、《颜如玉用户隐私政策》是“颜如玉”统一适用的一般性用户信息隐私条款，其中所规定的用户权利及信息安全保障措施均适用于“颜如玉”用户。如《颜如玉用户隐私政策》与本隐私保护指引存在不一致或矛盾之处，请以本隐私保护指引为准。<br/>
        2、如用户继续使用“颜如玉”提供的服务，则视为接受隐私权声明的变动。<br/>
        3、本《颜如玉用户隐私政策》的解释及争议解决均应适用中华人民共和国大陆地区法律。与本《颜如玉用户隐私政策》相关的任何纠纷，双方应协商友好解决；若不能协商解决，均同意将争议提交至广州仲裁委员会解决。<br/>
        <br/>
        如有任何问题，可以联系官方邮箱反馈：lqs888lqs@163.com<br/>
        您的任何反馈或者应用内举报，都会在15天内得到响应和处理<br/>
        广州颜如玉网络有限公司<br/>
        <br/>
    </div>
</template>

<script>
export default {
    name: "Privacy2",
    data() {
        return {
        }
    },
    mounted() {
        document.title = '颜如玉'
    }
}
</script>

<style lang="scss" scoped>
.privacy {
    text-align: left;
    padding: 15px 15px;
    font-size: 14px;
    line-height: 25px;
    &-top {
        font-size: 25px;
        font-weight: bold;
        line-height: 35px;
    }
    &-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 35px;
    }
    .title {
        font-weight: bold;
    }
    .title-big {
        font-size: 16px;
    }
}
</style>
